import { ref, watch, computed,getCurrentInstance } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useRoute } from 'vue-router' 

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const instance = getCurrentInstance()

  const route = instance.proxy.$route;

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label:"Frequency Name", sortable: true },
 
  
    { key: 'frequency',label:"Action" },
   
  
   
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const allCategories = ref(null)
  const selectedCategory = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchInstructers = (ctx, callback) => {

    var cat=selectedCategory.value==null||selectedCategory.value==undefined?0:selectedCategory.value.id;
   // const routeParams = route  // This contains your dynamic route params
   const routeParams = route.params  // Access route parameters
     let radioId=routeParams.id;

    console.log(routeParams);
    
    store
      .dispatch('apps-frequency/fetchAllPages', {
        radioId:radioId,
        currentPage: currentPage.value,
        perPage:perPage.value,
        searchQuery:searchQuery.value,
        c:cat,
        q: searchQuery.value,
        l: perPage.value,
        p:currentPage.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, totalposts } = response.data
console.log("some resposne");
        callback(data)
        totalUsers.value = totalposts
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status ===0) return 'disabled'
    if (status === 100) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusVariantText = status => {
    if (status ===0) return 'disabled'
    if (status === 100) return 'active'
    if (status === 2) return ''
    return 'primary'
  }
// Action 0 Nothing , Action 1 Call , Action 2 Navigateurl , Action 3 Launch Whatsapp
  const resolveUserActionVariantText = status => {
    if (status ===0) return 'Nothing'
    if (status === 1) return 'Call'
    if (status === 2) return 'Open Web'
      if (status === 3) return 'Whatsapp'
    return 'primary'
  }

  const resolveUserActionStatusVariant = status => {
    if (status ===0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }


  return {
    resolveUserActionStatusVariant,
    resolveUserActionVariantText,
    fetchInstructers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
